import Vue from 'vue'
import Vuex from 'vuex'
import axios from '../axios-auth'
//import globalAxios from 'axios'

import routes from '../router/index.js'
//import axios from '../axios-auth'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    idToken: null,
    user: null,
    name:null,
    languege:'gr',
    sidebarMap:false,
    poly_vertices:null,
    poi_data:null
  },
  mutations: {
    storeUser (state, user) {
      state.user = user
    },
    pagename (state, name){
      state.name = name
    },
    set (state, [variable, value]) {
      state[variable] = value
    },

  },
  actions: {

    async login ({commit}, authData) {
      //console.log(authData.username)


      await  axios.post('api-token/', {
        username: authData.username,
        password: authData.password,
      })
        .then(res => {
          localStorage.setItem('token', res.data.access)
          localStorage.setItem('user', authData.username)
          
          if(res.data.access !== null){ routes.replace('/dashboard')}
          commit('storeUser', authData.username)
        })
        .catch(error => console.log(error))
      
        /*axios.post('user-auth/', {
          username: authData.username,
          password: authData.password,
        },{ withCredentials: true })*/
      //commit('storeUser', authData.username)
      //this.$store.state.user=authData.username
    },
    logout () {
      localStorage.removeItem('token')
      localStorage.removeItem('username')
      //localStorage.removeItem('page_name')
      routes.replace('/')
      //location.reload();
    },
    pageName ({commit}, name) {
      //console.log(name)
      commit('pagename', name.name)
      //this.$store.state.user=authData.username
    },
    /*
    login ({commit, dispatch}, authData) {
      axios.post('http://127.0.0.1:8000/api-token/', {
        username: authData.username,
        password: authData.password,
       // returnSecureToken: true
      })
        .then(res => {
          //console.log(res)
          //const now = new Date()
          //const expirationDate = new Date(now.getTime() + res.data.expiresIn * 1000)
          localStorage.setItem('token', res.data.access)
          //localStorage.setItem('retoken', res.data.refresh)
          //context.commit('updateStorage', { access: res.data.access, refresh: response.data.refresh })


          //localStorage.setItem('userId', res.data.localId)
          //localStorage.setItem('expirationDate', expirationDate)
          
          //dispatch('setLogoutTimer', res.data.expiresIn)
          if(res.data.access !== null){ routes.push({ path: '/dashboard' })}
        })
        .catch(error => console.log(error))
    },*/
    

  //  logout ({commit}) {
     // localStorage.removeItem('expirationDate')
      //localStorage.removeItem('token')
      //localStorage.removeItem('retoken')
      //localStorage.removeItem('username')
      //localStorage.removeItem('page_name')
      //commit('storeUser', null)
    //  localStorage.removeItem('userId')
    //  routes.replace('/')
   // },
    /*fetchUser ({commit}) {

      const token = localStorage.getItem('token')
      const username = localStorage.getItem('username')

      axios.get('http://127.0.0.1:8000/user/'+ username, { headers: { Authorization: `Bearer ${token}` } })
        .then(response => {
          //console.log(response)
          commit('storeUser', response.data)
          // this.$store.state.APIData = response.data
        })
        .catch(err => {
          console.log(err)
        })
    }*/
  },
  getters: {
    user (state) {
      return state.user
    },
    PageName (state) {
      return state.name
    },
    isAuthenticated (state) {
      return state.user !== null
    }
  }
})
